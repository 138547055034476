
header{
    
    display: flex;

    & div {
        flex: 1;
        height: 8vh;
        margin-top: 2vh;
        & h1{
            text-align: center;
            font-size: 5vh;
        }
    }
}

body{
    background-color: #eeeeee;
}

@keyframes slideInFromLeft50 {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight50 {
    0% {
        transform: translateX(50%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes animOpacityToOpaque
{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animOpacityToTransparent
{
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes moveUpDown
{
    0%{
        bottom: 20px;
    }
    100%{
        bottom: 70px;
    }
}

@keyframes enlargeText
{
    0%{
        font-size: 15px;
    }
    100%{
        font-size: 20px;
    }
}


.game
{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 75vh;
    max-height: 75vh;

    max-width: 50%;
    margin-left: auto;
    margin-right: auto;

    &__poem{
        display: grid;
        align-items: center;
        justify-content: center;
        overflow-y: scroll;
        overflow-x: hidden;
        
        & p
        {
            margin-bottom: 0.2em;
            margin-top: 0px;
            text-align: center;

            animation: 1s ease 0s 1 slideInFromLeft50, 1s linear 0.3s 1 animOpacityToOpaque backwards;

        }

        &__word
        {
            color: black;
            display: inline-block;
            position: relative;


            &--matched
            {
                color: green;
            }

            &--highlight
            {
                font-weight: bolder;
            }

            &__overlay
            {
                position: absolute;
                word-wrap: none;
                text-align: center;
                vertical-align: middle;
                top: 0px;
                left: 0px;
                color: black;
                animation: 0.2s ease-in-out 0s enlargeText, 0.2s linear 0s 1 animOpacityToTransparent forwards; 
            }
        }
    }

    &__scrollpointer
    {
        position: absolute;
        right: 100px;
        bottom: 20px;
        background-color: transparent;

        animation: 1.5s ease 0s infinite animOpacityToOpaque both, 0.75s ease 0s infinite moveUpDown both;
        animation-direction: alternate;

        & span{
            font-size: 5em;
            color: #00000055;
            vertical-align: baseline;
        }
    }

    &__hidescrollbar
    {
        position: absolute;
        height: 100%;
        right: -5px;
        width: 20px;
        background-color: #eeeeee;
    }

    & button{
        display: block;
        width: 20em;
        margin-left: auto; margin-right: auto;

        height: 4em;
        border: 3px solid black;
        color: black;
        border-radius: 1em;
        border-width: 3px;

        font-size: 1.3em;

        //font-weight: bolder;

        transition: color 0.1s, border-color 0.1s, width 0.1s;

        &:hover{
            color: rgb(0, 0, 82);
            border-color: rgb(0, 0, 82);
            width: 22em;
            

            transition: color 0.2s, border-color 0.5s 0.1s, width 0.2s;

        }

        &:disabled{
            color: gray;
            border-color: gray;
            width: 20em;

            transition: color 0.2s, border-color 0.5s 0.1s, width 0.2s;
        }


    }

    &__input{
        width: 40em;
        max-width: 40em;
        background-color: white;
        margin-left: auto; margin-right: auto;
        margin-bottom: 0.5em;
        animation: 1s ease 0s 1 slideInFromLeft50, 1s linear 0.3s 1 animOpacityToOpaque backwards;
        background: transparent;

        & input{
            width: 100%;
            text-align: center;
            height: 10vh;
            font-size: 3vh;
            background: transparent;
            border: 1px solid #00000050;
            border-radius: 2%;
            transition: 0.1s linear 0s font-size;

            &:focus
            {
                border: 1px solid #00000050;
                background: transparent;
                border-radius: 2%;
                font-size: 3.2vh;
                outline: none;

                transition: 0.1s linear 0s font-size;
                caret-color: #00000050;
            }
        }

        &--dissapearing
        {
            animation: 0.5s ease 0s 1 slideToBottom both, 0.5s linear 0s 1 animOpacityToTransparent forwards;
            animation-fill-mode: forwards;
        }
    }

    &__startbutton
    {
        padding-bottom: 1em;

        &--dissapearing
        {
            animation: 0.3s ease 0.2s 1 slideToBottom both, 0.5s linear 0s 1 animOpacityToTransparent forwards;
            animation-fill-mode: forwards;
        }

        animation: 1s ease 0s 1 slideInFromLeft50, 1s linear 0.3s 1 animOpacityToOpaque backwards;
    }

    &__playagainbutton
    {
        padding-bottom: 1em;

        animation: 1s ease 0s 1 slideInFromLeft50, 1s linear 0.3s 1 animOpacityToOpaque backwards;
    }

    &__results
    {

    
        &--center{
            background-color: #eeeeeedd;
            animation: 0.55s linear 0.2s 1 animOpacityToOpaque backwards;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & p{
                text-align: center;
                font-size: 5vh;
                font-weight: bolder;
            }
        }

        &--bottomright
        {
            font-size: 2vh;
            text-align: right;
            padding-right: 2vw;
            padding-bottom: 2vh;

            animation: 0.5s linear 0s 1 animOpacityToOpaque backwards;
        }

        &--disappearing
        {
            animation: 0.5s linear 0s 1 animOpacityToTransparent forwards;
            animation-fill-mode: forwards;
        }

    }

    &__guessedwords
    {
        width: 35vw;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;

        padding-top: 80px;
        margin-right: 10px;

        &__title
        {
            position: absolute;
            top: 30px;
            text-align: center;
            width: 25vw;
        }
        & p
        {
            position: relative;
            display: block;
            background: lightgray;
            text-align: center;
            border-radius: 10px;
            padding-left: 5px;
            padding-right: 5px;
            animation: 1s ease 0s 1 slideInFromRight50, 1s linear 0.1s 1 animOpacityToOpaque backwards;
            margin: 5px 5px 0px 0px;
            width: 125px;
            word-wrap: break-word;

            transition: all 0.25s ease;

            &.--highlight
            {
                font-weight: bolder;
                background: white;

                transition: all 0.25s ease;
            }

            &.--isClicked
            {
                background: gray;
                transition: all 0.25 ease;
            }
        }
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}


.fixedcontainer
{
    &__bottomleft
    {
        position: fixed;
        bottom: 0px;
        left: 0px;
    }

    &__bottomright
    {
        position: fixed;
        bottom: 0px;
        right: 0px;
    }

    &__bottom
    {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }

    &__topright
    {
        position: fixed;
        top: 0px;
        right: 0px;
    }

    &__center
    {
        position: fixed;
        left: 20vw;
        right: 20vw;

        top: 30vh;
        bottom: 30vh;

    }

}
.poeminfo
{
    width: 30em;
    min-height: 5em;
    padding: 1em 1em 2em 2em;

    
    //box-shadow: 0px 0px 3px lightgray;

    animation: 0.5s ease 0.5s 1 slideInFromLeft backwards, 0.5s linear 0.2s 1 animOpacityToOpaque backwards;
}

@keyframes slideToBottom {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
}

.counter
{
    width: 10em;
    min-height: 5em;
    padding: 1em 2em 2em 1em;

    animation: 0.5s ease 1s 1 slideInFromLeft backwards, 0.5s linear 1.2s 1 animOpacityToOpaque backwards;

    & p{
        font-size: 3em;
        text-align: center;
    }
}

div.alert
{
    position: fixed;
    font-size: 5vw;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #c27d7dee;
    &__fullscreen
    {
        top: 2vh;
        bottom: 2vh;
        left: 2vh;
        right: 2vh;

    }
}

